import { z } from 'zod';

export const UsernameSchema = z
  .string({ required_error: 'Username is required' })
  .min(1, { message: 'Username is too short' });

export const PasswordSchema = z
  .string({ required_error: 'Password is required' })
  .min(1, { message: 'Password is too short' });

export const LoginSchema = z.object({
  username: UsernameSchema,
  password: PasswordSchema,
});

export const isValidUrl = (value?: string | null) => z.string().url().safeParse(value).success;
